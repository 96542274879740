import React, { Component, useState, useEffect } from "react";
import TicketDataService from "../services/ticket.service";
import { ReactComponent as ArrowSVG } from '../arrow.svg';
import { ReactComponent as QRScanSVG } from '../qr-scan.svg';
import { Link } from "react-router-dom";
import { withRouter } from '../common/with-router';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { Auth } from 'aws-amplify';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid

import { useNavigate } from "react-router-dom";

function TicketGrid({ ticketData, setActiveTicket }) {

    let navigate = useNavigate();

    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        { headerName: 'Date', field: "date", width: 120 },
        { headerName: 'First Name', field: "first_name", width: 110 },
        { headerName: 'Last Name', field: "last_name", width: 100 },
        { headerName: 'Email', field: "email", width: 150 },
        { headerName: 'Phone', field: "phone", width: 80 },
        { headerName: 'Free Promo', field: "promo", width: 120 },
        { headerName: 'Ticket Number', width: 150, field: "ticket_number" }
    ]);

    return (
        // wrapping container with theme & size
        <div
            className="ag-theme-quartz" // applying the Data Grid theme
            style={{ height: 500 }} // the Data Grid will fill the size of the parent container
        >
            <AgGridReact
                rowData={ticketData}
                columnDefs={colDefs}
                pagination="true"
                onRowClicked={(e) => {
                    setActiveTicket(e.data, 0)
                    navigate("/ticket/" + e.data.id)
                }
                }
            />
        </div>
    )

}



class TicketsListQR extends Component {


    constructor(props) {
        super(props);
        this.refreshList = this.refreshList.bind(this);
        this.retrieveTickets = this.retrieveTickets.bind(this);
        this.setActiveTicket = this.setActiveTicket.bind(this);
        this.searchFirstName = this.searchFirstName.bind(this);
        this.searchEmail = this.searchEmail.bind(this);
        this.onChangeSearchTicket_number = this.onChangeSearchTicket_number.bind(this);
        this.onChangeSearchFirst_name = this.onChangeSearchFirst_name.bind(this);
        this.onChangeSearchConsumer_can_collect = this.onChangeSearchConsumer_can_collect.bind(this);
        this.onChangeSearchConsumer_can_collect2 = this.onChangeSearchConsumer_can_collect2.bind(this);
        this.onChangeSearchConsumer_collected = this.onChangeSearchConsumer_collected.bind(this);
        this.onChangeSearchEmail = this.onChangeSearchEmail.bind(this);
        this.onChangeSearchLast_name = this.onChangeSearchLast_name.bind(this);
        this.onChangeSearchMarketing = this.onChangeSearchMarketing.bind(this);
        this.onChangeSearchName = this.onChangeSearchName.bind(this);
        this.onChangeSearchPhone = this.onChangeSearchPhone.bind(this);
        this.onChangeSearchPrivacy = this.onChangeSearchPrivacy.bind(this);
        this.onChangeSearchRepairer_picked_up = this.onChangeSearchRepairer_picked_up.bind(this);
        this.onChangeSearchRepairer_can_collect = this.onChangeSearchRepairer_can_collect.bind(this);






        console.log("State Start");



        this.state = {
            tickets: [],
            currentTicket: null,
            currentIndex: -1,
            searchTicket_number: "",
            searchFirst_name: "",
            searchConsumer_can_collect: "",
            searchConsumer_can_collect2: "",
            searchConsumer_collected: "",
            searchEmail: "",
            searchLast_name: "",
            searchMarketing: "",
            searchName: "",
            searchPhone: "",
            searchPrivacy: "",
            searchRepairer_picked_up: "",
            searchStore: "",
            searchRepairer_can_collect: "",
            showQR: false,
            storeName: "",
            group: '',
            storeSet: ''


        };

        this.searchAll = this.searchAll.bind(this);



    }

    async componentDidMount() {
        const user = await Auth.currentAuthenticatedUser();
        console.log(user);
        const groupname = user.signInUserSession.accessToken.payload["cognito:groups"];
        this.setState({ group: groupname });

        console.log(groupname)
        if (groupname[0] === "admin" || groupname[0] === "brand" || groupname[0] === "repairer") {
            this.setState({ storeSet: "true" });
            this.retrieveTickets(this.props.router.params.store);
        } else if (groupname[0] === "tnf") {

            this.setState({ storeSet: "true" });
            this.retrieveTickets(groupname[0]);
        }
    }


    componentDidUpdate(prevProps, prevState) {
        /**
        * this is the initial render
        * without a previous prop change
        */
        if (prevProps == undefined) {
            return false
        }



        /**
         * new Project in town ?
         */
        if (this.state.storeName != this.props.router.params.store) {


            this.retrieveTickets(this.props.router.params.store)
            this.setState({ storeName: this.props.router.params.store })
        }

    }

    onChangeSearchTicket_number(e) {
        const searchTicket_number = e.target.value;

        this.setState({
            searchTicket_number: searchTicket_number
        }, () => {

            this.searchAll();
        });
    }
    onChangeSearchFirst_name(e) {
        const searchFirst_name = e.target.value;

        this.setState({
            searchFirst_name: searchFirst_name
        }, () => {

            this.searchAll();
        });
    }
    onChangeSearchConsumer_can_collect(e) {
        let searchRepairer_can_collect = "";
        let searchRepairer_picked_up = "";
        let searchConsumer_can_collect = "1";
        let searchConsumer_can_collect2 = "0";
        let searchConsumer_collected = "0";


        this.setState({
            searchRepairer_can_collect: searchRepairer_can_collect,
            searchConsumer_can_collect: searchConsumer_can_collect,
            searchConsumer_can_collect2: searchConsumer_can_collect2,
            searchConsumer_collected: searchConsumer_collected,
            searchRepairer_picked_up: searchRepairer_picked_up
        }, () => {

            this.searchAll();
        });
    }


    onChangeSearchConsumer_can_collect2(e) {
        let searchRepairer_can_collect = "";
        let searchRepairer_picked_up = "";
        let searchConsumer_can_collect = "1";
        let searchConsumer_can_collect2 = "1";
        let searchConsumer_collected = "0";


        this.setState({
            searchRepairer_can_collect: searchRepairer_can_collect,
            searchConsumer_can_collect: searchConsumer_can_collect,
            searchConsumer_can_collect2: searchConsumer_can_collect2,
            searchConsumer_collected: searchConsumer_collected,
            searchRepairer_picked_up: searchRepairer_picked_up
        }, () => {

            this.searchAll();
        });
    }
    onChangeSearchConsumer_collected(e) {

        let searchRepairer_can_collect = "";
        let searchRepairer_picked_up = "";
        let searchConsumer_can_collect = "1";
        let searchConsumer_collected = "1";


        this.setState({
            searchRepairer_can_collect: searchRepairer_can_collect,
            searchConsumer_can_collect: searchConsumer_can_collect,
            searchConsumer_collected: searchConsumer_collected,
            searchRepairer_picked_up: searchRepairer_picked_up
        }, () => {

            this.searchAll();
        });
    }
    onChangeSearchEmail(e) {
        const searchEmail = e.target.value;

        this.setState({
            searchEmail: searchEmail
        }, () => {

            this.searchAll();
        });
    }
    onChangeSearchLast_name(e) {
        const searchLast_name = e.target.value;

        this.setState({
            searchLast_name: searchLast_name
        }, () => {

            this.searchAll();
        });
    }
    onChangeSearchMarketing(e) {
        let searchMarketing = "0";

        if (e.target.checked)
            searchMarketing = "1";


        this.setState({
            searchMarketing: searchMarketing
        }, () => {

            this.searchAll();
        });
    }
    onChangeSearchName(e) {
        const searchName = e.target.value;
        this.setState({
            searchName: searchName
        }, () => {

            this.searchAll();
        });
    }
    onChangeSearchPhone(e) {
        const searchPhone = e.target.value;

        this.setState({
            searchPhone: searchPhone
        }, () => {

            this.searchAll();
        });
    }
    onChangeSearchPrivacy(e) {
        let searchPrivacy = "0";
        if (e.target.checked)
            searchPrivacy = "1";
        this.setState({
            searchPrivacy: searchPrivacy
        }, () => {

            this.searchAll();
        });
    }
    onChangeSearchRepairer_picked_up(e) {

        let searchRepairer_can_collect = "";
        let searchRepairer_picked_up = "1";
        let searchConsumer_can_collect = "0";
        let searchConsumer_collected = "0";


        this.setState({
            searchRepairer_can_collect: searchRepairer_can_collect,
            searchConsumer_can_collect: searchConsumer_can_collect,
            searchConsumer_collected: searchConsumer_collected,
            searchRepairer_picked_up: searchRepairer_picked_up
        }, () => {

            this.searchAll();
        });
    }
    onChangeSearchStore(e) {
        const searchStore = e.target.value;

        this.setState({
            searchStore: searchStore
        }, () => {

            this.searchAll();
        });
    }
    onChangeSearchRepairer_can_collect(e) {
        let searchRepairer_can_collect = "1";
        let searchRepairer_picked_up = "0";
        let searchConsumer_can_collect = "0";
        let searchConsumer_collected = "0";


        this.setState({
            searchRepairer_can_collect: searchRepairer_can_collect,
            searchConsumer_can_collect: searchConsumer_can_collect,
            searchConsumer_collected: searchConsumer_collected,
            searchRepairer_picked_up: searchRepairer_picked_up
        }, () => {

            this.searchAll();
        });
    }

    retrieveTickets(store) {

        let storeName = "";
        if (store) {
            if (store.includes("tnf"))
                storeName = "h Face";
            console.log(storeName);
        }
        this.setState({
            searchStore: storeName
        }, () => {
            TicketDataService.getAll()
                .then(response => {
                    this.setState({
                        tickets: response.data
                    });

                })
                .catch(e => {
                    console.log(e);
                });

        })
    }

    refreshList() {
        this.retrieveTickets();
        this.setState({
            currentTicket: null,
            currentIndex: -1
        });
    }

    setActiveTicket(ticket, index) {

        this.setState({
            currentTicket: ticket,
            currentIndex: index
        });
    }

    searchFirstName() {
        TicketDataService.findByFirstName(this.state.searchFirstName)
            .then(response => {
                this.setState({
                    tickets: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);

            });
    }




    searchAll() {
        let storeName = "";
        if (this.props.router.params.id) {
            if (this.props.router.params.id.includes("tnf"))
                storeName = "";
        }

        console.log("Picked " + this.state.searchName + " - " + this.state.searchStore);

        TicketDataService.findBySearch(this.state.searchTicket_number, this.state.searchFirst_name, this.state.searchConsumer_can_collect, this.state.searchConsumer_can_collect2, this.state.searchConsumer_collected,
            this.state.searchEmail, this.state.searchLast_name, this.state.searchMarketing, this.state.searchName, this.state.searchStore,
            this.state.searchRepairer_picked_up, this.state.searchPrivacy, this.state.searchPhone, this.state.searchRepairer_can_collect)
            .then(response => {
                this.setState({
                    tickets: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);

            });

    }



    searchEmail() {
        TicketDataService.findByEmail(this.state.searchEmail)
            .then(response => {
                this.setState({
                    tickets: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);

            });
    }

    render() {




        const { searchFirst_name, searchEmail, searchRepairer_can_collect,
            searchConsumer_collected, searchConsumer_can_collect, searchLast_name, searchMarketing,
            searchName, searchPhone, searchPrivacy,
            searchRepairer_picked_up, searchStore,
            searchTicket_number, tickets, currentTicket, currentIndex, showQR } = this.state;



        return (
            <div>
                <div className="row">
                    <div className="d-flex col-md-8 offset-md-2 justify-content-center" >
                        <h4>Ticket lookup</h4>
                    </div>
                </div>

                <div className="row">
                    <div className="d-flex col-md-8 offset-md-2 justify-content-center" >
                        <p className=".lead">Scan the ticket QR code to find the ticket. You may also search manually by providing customer information.</p>
                    </div>
                </div>
                <div className="list row">

                    <div className="d-flex col-md-3 offset-md-2 justify-content-center check-text">
                        <button type="button" className="image-btn" data-bs-toggle="modal" data-bs-target="#qrModal">
                            <div className="qr-scan-btn" onClick={() => {
                                console.log("test")
                                this.setState({
                                    showQR: true
                                });
                            }
                            }>
                                <QRScanSVG />

                            </div>
                        </button>
                    </div>
                    <div className="d-flex col-md-5 offset-md-1 justify-content-center check-text">
                        <ul className="flat-list-2">
                            <li className="flat-list-item">

                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Name"
                                    value={searchName}
                                    onChange={
                                        this.onChangeSearchName
                                    }
                                />
                            </li>
                            <li className="flat-list-item">

                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Email"
                                    value={searchEmail}
                                    onChange={
                                        this.onChangeSearchEmail
                                    }

                                />

                            </li>
                            <li className="flat-list-item">


                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Unique_ID"
                                    value={searchTicket_number}
                                    onChange={
                                        this.onChangeSearchTicket_number
                                    }
                                />

                            </li>
                            <li className="flat-list-item">
                                <select className="form-select" id="otherSelect" aria-label="Default select example"
                                    onChange={() => {


                                        const selectObject = document.getElementById("otherSelect");
                                        const parentObject = document.getElementById("otherParent");
                                        const hiddenObject = document.getElementById("otherHidden");

                                        const otherRepairerCan = document.getElementById("otherRepairerCan");
                                        const otherConsumerCan = document.getElementById("otherConsumerCan");
                                        const otherConsumerCollected = document.getElementById("otherConsumerCollected");
                                        const otherRepairerPickedUp = document.getElementById("otherRepairerPickedUp");


                                        otherRepairerCan.firstChild.value = "";
                                        otherRepairerCan.firstChild.checked = false;
                                        otherConsumerCan.firstChild.value = "";
                                        otherConsumerCan.firstChild.checked = false;
                                        otherConsumerCollected.firstChild.value = "";
                                        otherConsumerCollected.firstChild.checked = false;
                                        otherRepairerPickedUp.firstChild.value = "";
                                        otherRepairerPickedUp.firstChild.checked = false;

                                        hiddenObject.appendChild(otherRepairerPickedUp);
                                        hiddenObject.appendChild(otherConsumerCollected);
                                        hiddenObject.appendChild(otherConsumerCan);
                                        hiddenObject.appendChild(otherRepairerCan);



                                        var selected = selectObject.options[selectObject.selectedIndex].value;
                                        console.log(selected);
                                        switch (selected) {
                                            case "0":
                                                //parentObject.appendChild(otherCleanerCan);
                                                this.onChangeSearchRepairer_can_collect();
                                                break;
                                            case "1":
                                                //parentObject.appendChild(otherShoePickedUp);
                                                this.onChangeSearchRepairer_picked_up();
                                                break;
                                            case "2":
                                                //parentObject.appendChild(otherConsumerCan);
                                                this.onChangeSearchConsumer_can_collect();
                                                break;
                                            case "3":
                                                //parentObject.appendChild(otherConsumerCollected);
                                                this.onChangeSearchConsumer_collected();
                                                break;


                                        }



                                    }}
                                >
                                    <option defaultValue>Select Other</option>
                                    <option value="0">Repairer Can Collect</option>
                                    <option value="1">Repairer Picked Up</option>
                                    <option value="2">Consumer Can Collect</option>
                                    <option value="3">Consumer Collected</option>

                                </select>

                            </li>
                            <li className="flat-list-item">
                                <div id="otherParent">

                                </div>


                            </li>
                            <li>
                                <div id="otherHidden" className="hidden">


                                    <div id="otherRepairerCan" className="input-group mb-3">
                                        <input
                                            type="text"
                                            className=""
                                            placeholder="Search Repairer Can Collect"
                                            onChange={
                                                this.onChangeSearchRepairer_can_collect
                                            }

                                        />

                                    </div>
                                    <div id="otherConsumerCan" className="input-group mb-3">
                                        <input
                                            type="checkBox"
                                            className=""
                                            onChange={
                                                this.onChangeSearchConsumer_can_collect
                                            }

                                        />

                                    </div>
                                    <div id="otherConsumerCollected" className="input-group mb-3">
                                        <input
                                            type="checkBox"
                                            className=""
                                            onChange={
                                                this.onChangeSearchConsumer_collected
                                            }

                                        />

                                    </div>



                                    <div id="otherRepairerPickedUp" className="input-group mb-3">
                                        <input
                                            type="checkBox"
                                            className=""
                                            onChange={
                                                this.onChangeSearchRepairer_picked_up
                                            }

                                        />

                                    </div>


                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-12 mt-2 mb-2">
                        Tickets
                    </div>
                    <div className="col-md-12">
                        <ul className="list-group">




                            <TicketGrid ticketData={tickets} setActiveTicket={this.setActiveTicket} />
                        </ul>


                    </div>
                </div>

                <div className="modal fade" id="qrModal" tabIndex="-1" data-bs-backdrop="static"
                    data-bs-keyboard="false" aria-labelledby="qrModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="qrModalLabel">Scan QR Code</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                    console.log("test")
                                    this.setState({
                                        showQR: false
                                    });
                                }} >


                                </button>
                            </div>
                            <div className="modal-body">

                                {showQR &&
                                    <QrScanner
                                        onDecode={(result) => {
                                            console.log(result)
                                            this.setState({
                                                showQR: false
                                            });


                                            const modal = document.getElementById('qrModal');

                                            // change state like in hidden modal
                                            modal.classList.remove('show');
                                            modal.setAttribute('aria-hidden', 'true');
                                            modal.setAttribute('style', 'display: none');

                                            // get modal backdrop
                                            const modalBackdrops = document.getElementsByClassName('modal-backdrop');

                                            // remove opened modal backdrop
                                            document.body.removeChild(modalBackdrops[0]);


                                            this.setState({
                                                searchTicket_number: result
                                            }, () => {

                                                this.searchAll();
                                            });

                                            console.log(showQR);
                                        }}
                                        onError={(error) => console.log(error?.message)}
                                    />}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => {
                                    console.log("test")
                                    this.setState({
                                        showQR: false
                                    });
                                }
                                }>Close</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}


export default withRouter(TicketsListQR);