import React, { Component } from "react";
import TicketDataService from "../services/ticket.service";

export default class ViewStats extends Component {
    constructor(props) {
        super(props);
        this.retrieveTickets = this.retrieveTickets.bind(this);
        this.refreshList = this.refreshList.bind(this);
        //this.onChangeSearchStore = this.onChangeSearchStore.bind(this);
        this.state = {
            tickets: [],
            currentTicket: null,
            currentIndex: -1,
            searchConsumer_can_collect: "",
            searchConsumer_can_collect2: "",
            searchConsumer_collected: "",
            searchStore: "",
            searchRepairer_picked_up: "",
            searchRepairer_can_collect: "",
            promo: ""


        };
    }

    componentDidMount() {
        this.retrieveTickets();
    }
    onChangeSearchStore(e) {
        const searchStore = e;

        this.setState({
            searchStore: searchStore
        }, () => {

            this.searchAll();
        });
    }
    searchAll() {
        const totalCount = document.getElementById("totalNum");
        const totalAwaiting = document.getElementById("awaitingNum");
        const totalRepairer = document.getElementById("repairerNum");
        const totalReturned = document.getElementById("returnedNum");
        const totalCollected = document.getElementById("collectedNum");
        const totalRepaired = document.getElementById("repairedNum");

        TicketDataService.findByStoreSearchTotal(this.state.searchStore)
            .then(response => {
                this.setState({
                    tickets: response.data
                });
                console.log(response.data)
                totalCount.innerHTML = response.data;

            })
            .catch(e => {
                console.log(e);
            });

        TicketDataService.findByStoreSearchAwaiting(this.state.searchStore)
            .then(response => {

                totalAwaiting.innerHTML = response.data;
                console.log("Length = " + response.data)

            })
            .catch(e => {
                console.log(e);

            });

        TicketDataService.findByStoreSearchCleaner(this.state.searchStore)
            .then(response => {

                totalRepairer.innerHTML = response.data;
                console.log("CLeaner 2 = " + response.data)

            })
            .catch(e => {
                console.log(e);

            });


        TicketDataService.findByStoreSearchReturned(this.state.searchStore)
            .then(response => {

                totalReturned.innerHTML = response.data;

            })
            .catch(e => {
                console.log(e);

            });


        TicketDataService.findByStoreSearchCollected(this.state.searchStore)
            .then(response => {

                totalCollected.innerHTML = response.data;

            })
            .catch(e => {
                console.log(e);

            });

            TicketDataService.findByStoreSearchRepair(this.state.searchStore)
            .then(response => {
               
                totalRepaired.innerHTML = response.data;

            })
            .catch(e => {
                console.log(e);

            });

       


    }

    retrieveTickets() {
        const totalCount = document.getElementById("totalNum");
        const totalAwaiting = document.getElementById("awaitingNum");
        const totalRepairer = document.getElementById("repairerNum");
        const totalReturned = document.getElementById("returnedNum");
        const totalCollected = document.getElementById("collectedNum");
        const totalRepaired = document.getElementById("repairedNum");
        TicketDataService.getAll()
            .then(response => {
                this.setState({
                    tickets: response.data
                });
                console.log(response.data.length)
                totalCount.innerHTML = response.data.length;

            })
            .catch(e => {
                console.log(e);
            });

        TicketDataService.findBySearchAwaiting()
            .then(response => {

                totalAwaiting.innerHTML = response.data;
                console.log("Length = " + response.data)

            })
            .catch(e => {
                console.log(e);

            });

        TicketDataService.findBySearchRepairer()
            .then(response => {

                totalRepairer.innerHTML = response.data;
                console.log("Repairer 2 = " + response.data)

            })
            .catch(e => {
                console.log(e);

            });


        TicketDataService.findBySearchReturned()
            .then(response => {

                totalReturned.innerHTML = response.data;

            })
            .catch(e => {
                console.log(e);

            });


        TicketDataService.findBySearchCollected()
            .then(response => {

                totalCollected.innerHTML = response.data;

            })
            .catch(e => {
                console.log(e);

            });

            TicketDataService.findByStoreSearchRepair(this.state.searchStore)
            .then(response => {
               
                totalRepaired.innerHTML = response.data;

            })
            .catch(e => {
                console.log(e);

            });



    }


    refreshList() {
        const totalCount = document.getElementById("totalNum");
        this.retrieveTickets();
        this.setState({
            currentTicket: null,
            currentIndex: -1
        });
    }

    render() {


        const { searchRepairer_can_collect,
            searchConsumer_collected, searchConsumer_can_collect,searchConsumer_can_collect2, searchRepairer_picked_up, searchStore, tickets } = this.state;
        return (
            <div className="submit-form">

                <div>
                    <div className="row">
                        <div className="d-flex col-md-8 offset-md-2 justify-content-center" >
                            <h4 className="title">Total Tickets</h4>
                        </div>
                    </div>


                    <div className="row">


                        <div className="d-flex col-md-8 offset-md-2 justify-content-center ">

                            <select defaultValue={0} className="form-select" aria-label="Default select example" id="storeSelect"
                                onChange={() => {

                                    const selectObject = document.getElementById("storeSelect");
                                    var selected = selectObject.options[selectObject.selectedIndex].value;
                                    console.log(selected);
                                    switch (selected) {
                                        case "0":
                                            this.onChangeSearchStore("")
                                            break;
                                    }
                                }}
                            >
                                <option>Select Store</option>
                                <option value="0">The North Face</option>
                            </select>






                        </div>
                    </div>

                    <div className="row">
                        <div className="d-flex col-md-8 offset-md-2 justify-content-center  mt-3">
                            <div className="white-stats">
                                <h1 id="totalNum">203</h1>
                                <h5>Since creation</h5>
                            </div>





                        </div>
                    </div>

                    <div className="row">
                        <div className="d-flex col-md-2 offset-md-2 justify-content-center  mt-3">
                            <div className="grey-stats">
                                <h1 id="awaitingNum">203</h1>
                                <h5>Awaiting Collection</h5>
                            </div>





                        </div> <div className="d-flex col-md-2 justify-content-center  mt-3">
                            <div className="grey-stats">
                                <h1 id="repairerNum">203</h1>
                                <h5>Items with repairer</h5>
                            </div>





                        </div> <div className="d-flex col-md-2 justify-content-center  mt-3">
                            <div className="grey-stats">
                                <h1 id="returnedNum">203</h1>
                                <h5>Returned to store</h5>
                            </div>





                        </div> <div className="d-flex col-md-2  justify-content-center  mt-3">
                            <div className="grey-stats">
                                <h1 id="collectedNum">203</h1>
                                <h5>Collected by customer</h5>
                            </div>





                        </div>
                    </div>


                    <div className="row">
                        <div className="d-flex col-md-8 offset-md-2 justify-content-start" >
                            <p className="small-title">Awaiting collection</p>
                        </div>
                    </div>

                    <div className="row">
                   <div className="d-flex col-md-4 offset-md-2 justify-content-center  mt-3">
                            <div className="grey-stats">
                                <h1 id="repairedNum">203</h1>
                                <h5>To be repaired</h5>
                            </div>








                        </div>
                    </div>







                </div>




            </div>
        );

    }
}