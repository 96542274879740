import React, { Component } from "react";
import TicketDataService from "../services/ticket.service";
import { withRouter } from '../common/with-router';
import ticketService from "../services/ticket.service";
import { Modal } from 'bootstrap'
import { Auth } from 'aws-amplify';


class PhotoList extends Component {

    constructor(props) {
        super(props);
        console.log(props.itemid);
        this.retrievePhotos = this.retrievePhotos.bind(this);

        this.state = {
            photos: [],
            id: null,
            itemid: null,
            searchTicketID: ""
        };

        //this.searchAll = this.searchAll.bind(this);
    }

    async componentDidMount() {
        const user = await Auth.currentAuthenticatedUser();

        const groupname = user.signInUserSession.accessToken.payload["cognito:groups"];
        this.setState({ group: groupname });

        console.log("X = " + this.props.router.params);
        console.log("Y = " + this.props.router.params.id);
        console.log(this.props.router.params.itemid);
        this.retrievePhotos(this.props.router.params.itemid);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps == undefined) {
            return false;
        }



    }

    retrievePhotos(itemid) {
        this.setState({
            itemid: this.props.itemid
        }, () => {
            TicketDataService.getPhotos(this.props.itemid)
                .then(response => {
                    this.setState({
                        photos: response.data
                    });
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(2);

                })
        })
    }




    render() {
        const { photos,itemid, searchTicketID } = this.state;

        return (


            <div>
                <div className="col-md-12 mt-2 mb-2">
                    Photos
                </div>
                <div className="row ">
                    <div className="d-flex col-md-4 check-text">
                        <button type="button" className="image-btn" data-bs-toggle="modal" data-bs-target={"#image1modal"+itemid}>
                        {photos[0] ? <img className="shoe-image" src={photos[0].url?photos[0].url:""}/>: <span />}
                        </button>



                    </div>


                    {(photos[1] &&photos[1].url.length>45)?
                    <div className="d-flex col-md-4 check-text">
                        <button type="button" className="image-btn" data-bs-toggle="modal" data-bs-target={"#image2modal"+itemid}>

                         <img className="shoe-image" src={photos[1].url}/>
                        </button>
                    </div>
: <span />}

{(photos[2] &&photos[2].url.length>45)?
                    <div className="d-flex col-md-4 check-text">
                        <button type="button" className="image-btn" data-bs-toggle="modal" data-bs-target={"#image3modal"+itemid}>

                         <img className="shoe-image" src={photos[2].url}/>
                        </button>
                    </div>
: <span />}
               
</div>



                <div className="modal" id={"image1modal"+itemid} tabIndex="-1" data-bs-backdrop="static"
                    data-bs-keyboard="false" aria-labelledby="deleteModal2Label" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="deleteModal2Label">Shoe Image 1</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">

                                </button>
                            </div>
                            <div className="modal-body">
                                <img className="shoe-image" src={photos[0] ? photos[0].url : ""} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal" id={"image2modal"+itemid} tabIndex="-1" data-bs-backdrop="static"
                    data-bs-keyboard="false" aria-labelledby="deleteModal2Label" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="deleteModal2Label">Shoe Image 2</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">

                                </button>
                            </div>
                            <div className="modal-body">
                                <img className="shoe-image" src={photos[1] ? photos[1].url : ""} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal" id={"image3modal"+itemid} tabIndex="-1" data-bs-backdrop="static"
                    data-bs-keyboard="false" aria-labelledby="deleteModal2Label" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="deleteModal2Label">Shoe Image 3</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">

                                </button>
                            </div>
                            <div className="modal-body">
                                <img className="shoe-image" src={photos[2] ? photos[2].url : ""} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                        </div>
                    </div>
                </div>


            </div>


        );

    }
}

export default withRouter(PhotoList);