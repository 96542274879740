import http from "../http-common";

class TicketDataService {
    getAll() {
        return http.get("/tickets");
    }
    getStore(store) {
        return http.get(`/tickets?store=${store}`);
    }

    get(id) {
        return http.get(`/tickets/${id}`);
    }

    create(data) {
        return http.post("/tickets", data);
    }

    update(id, data) {
        console.log(data);
        return http.put(`/tickets/${id}`, data);
    }
    findBySearch(ticket_number, first_name, consumer_can_collect,consumer_can_collect2, consumer_collected, email, last_name, marketing, name,
        store, repairer_picked_up, privacy, phone, repairer_can_collect) {
        console.log("Rep = " + repairer_can_collect);
        return http.get(`/tickets?ticket_number=${ticket_number}&first_name=${first_name}&consumer_can_collect=${consumer_can_collect}&consumer_collected=${consumer_collected}&email=${email}&last_name=${last_name}&marketing=${marketing}&name=${name}&store=${store}&repairer_picked_up=${repairer_picked_up}&privacy=${privacy}&phone=${phone}&repairer_can_collect=${repairer_can_collect}`);
    }

    findByStoreSearchTotal(store) {
        return http.get(`/tickets/stats?store=${store}`);
    }

    getItems(userid) {
        return http.get(`/tickets/items?userid=${userid}`)
    }
    getPhotos(itemid) {
        return http.get(`/tickets/photos?itemid=${itemid}`)
    }
    sendEmailRepairing(data) {
        return http.post("/tickets/sendemailrepairing", data);

    }


    sendEmailReady(data) {
        console.log("Sending Email");
        return http.post("/tickets/sendemailready", data);

    }

    sendEmailThankyou(data) {
        return http.post("/tickets/sendemailthankyou", data);

    }

    sendTextRepairing(data) {

        console.log("Sending Text");
        return http.post("/tickets/sendSMS", data);
    }

    sendText(data) {

        return http.post("/tickets/sendtext3", data);
    }
    findBySearchAwaiting() {
        console.log("Await");
        return http.get(`/tickets/stats?repairer_picked_up=0`);
    }
    findBySearchRepairer() {
        return http.get(`/tickets/stats?repairer_picked_up=1&consumer_can_collect=0&consumer_collected=0`);
    }

    findBySearchReturned() {
        return http.get(`/tickets/stats?consumer_can_collect=1&consumer_collected=0`);
    }
    findBySearchCollected() {
        return http.get(`/tickets/stats?consumer_collected=1`);
    }


    findByStoreSearchAwaiting(store) {
        console.log("Await");
        return http.get(`/tickets/stats?repairer_picked_up=0&store=${store}`);
    }
    findByStoreSearchCleaner(store) {
        return http.get(`/tickets/stats?repairer_picked_up=1&consumer_can_collect=0&consumer_collected=0&store=${store}`);
    }

    findByStoreSearchReturned(store) {
        return http.get(`/tickets/stats?consumer_can_collect=1&consumer_collected=0&store=${store}`);
    }
    findByStoreSearchCollected(store) {
        return http.get(`/tickets/stats?consumer_collected=1&store=${store}`);
    }

    findByStoreSearchRepair(store) {
        return http.get(`/tickets/stats?shoe_picked_up=0&store=${store}`);
    }




    delete(id) {
        return http.delete(`/tickets/${id}`);
    }

    findByFirstName(uid) {
        return http.get(`/tickets?first_name=${uid}`);
    }
}

export default new TicketDataService();