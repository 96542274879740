import React, { Component } from "react";
import TicketDataService from "../services/ticket.service";
import { withRouter } from '../common/with-router';
import ticketService from "../services/ticket.service";
import { Modal } from 'bootstrap'
import { Auth } from 'aws-amplify';
import ItemsList from "./item-list.component";
import { Link } from "react-router-dom";



class Ticket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTicket: {
                id: null,
                userid: null,
                ticket_number: null,
                first_name: "",
                consumer_can_collect: false,
                consumer_can_collect2: false,
                consumer_collected: false,
                promo: false,
                email: "",
                last_name: "",
                marketing: false,
                store: "",
                repairer_picked_up: false,
                privacy: false,
                phone: "",
                repairer_can_collect: false,
                date: null,
                notes: "",
                group: '',
                storeSet: '',

            },
            message: ""
        };
        this.onChangeRepairerPickedUp = this.onChangeRepairerPickedUp.bind(this);
        this.onChangeConsumerCan = this.onChangeConsumerCan.bind(this);
        this.onChangeConsumerCan2 = this.onChangeConsumerCan2.bind(this);
        this.onChangeConsumerCollect = this.onChangeConsumerCollect.bind(this);
        this.onChangePromo = this.onChangePromo.bind(this);
        this.onChangeNotes = this.onChangeNotes.bind(this);
        this.getTicket = this.getTicket.bind(this);
        this.updateTicket = this.updateTicket.bind(this);
        this.deleteTicket = this.deleteTicket.bind(this);
        this.saveNotes = this.saveNotes.bind(this);




    }

    async componentDidMount() {
        const user = await Auth.currentAuthenticatedUser();

        const groupname = user.signInUserSession.accessToken.payload["cognito:groups"];
        this.setState({
            group: groupname[0],
            userid: this.props.router.params.id
        });


        this.getTicket(this.props.router.params.id);
    }


    saveNotes(status) {
        var data = {
            id: this.state.currentTicket.id,
            ticket_number: this.state.currentTicket.ticket_number,
            first_name: this.state.currentTicket.first_name,
            consumer_can_collect: this.state.currentTicket.consumer_can_collect,
            consumer_can_collect2: this.state.currentTicket.consumer_can_collect2,
            consumer_collected: this.state.currentTicket.consumer_collected,
            promo: this.state.currentTicket.promo,
            email: this.state.currentTicket.email,
            last_name: this.state.currentTicket.last_name,
            marketing: this.state.currentTicket.marketing,
            store: this.state.currentTicket.store,
            repairer_picked_up: this.state.currentTicket.repairer_picked_up,
            privacy: this.state.currentTicket.privacy,
            phone: this.state.currentTicket.phone,
            repairer_can_collect: this.state.currentTicket.repairer_can_collect,
            date: this.state.currentTicket.date,
            notes: this.state.currentTicket.notes
        }

        console.log("Notes = " + data.notes);
        ticketService.update(this.state.currentTicket.id, data)
            .then(response => {
                this.setState(prevState => ({
                    currentTicket: {
                        ...prevState.currentTicket,
                        notes: data.notes
                    }
                }));


            })
            .catch(e => {
                console.log(e);
            });
    }

    onChangeNotes(e) {
        this.setState(prevState => ({
            currentTicket: {
                ...prevState.currentTicket,
                notes: e.target.value
            }
        }));
    }
    onChangeRepairerPickedUp(status) {

        var data = {
            id: this.state.currentTicket.id,
            ticket_number: this.state.currentTicket.ticket_number,
            first_name: this.state.currentTicket.first_name,
            consumer_can_collect: this.state.currentTicket.consumer_can_collect,
            consumer_can_collect2: this.state.currentTicket.consumer_can_collect2,
            consumer_collected: this.state.currentTicket.consumer_collected,
            promo: this.state.currentTicket.promo,
            email: this.state.currentTicket.email,
            last_name: this.state.currentTicket.last_name,
            marketing: this.state.currentTicket.marketing,
            store: this.state.currentTicket.store,
            repairer_picked_up: !this.state.currentTicket.repairer_picked_up,
            privacy: this.state.currentTicket.privacy,
            phone: this.state.currentTicket.phone,
            repairer_can_collect: this.state.currentTicket.repairer_can_collect,
            date: this.state.currentTicket.date,
            notes: this.state.currentTicket.notes
        }

        ticketService.update(this.state.currentTicket.id, data)
            .then(response => {
                this.setState(prevState => ({
                    currentTicket: {
                        ...prevState.currentTicket,
                        repairer_picked_up: data.repairer_picked_up
                    }
                }));

                if (data.repairer_picked_up) {
                    console.log("SENDING EMAIL 1");
                    ticketService.sendEmailRepairing(data).then(response => {
                        console.log("Email sent");
                        ticketService.sendTextRepairing(data).then(response => {
                            console.log("Text sent");
                        }).catch(e => {
                            console.log(e);
                        })
                    }).catch(e => {
                        console.log(e);
                    })
                }
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }


    onChangeConsumerCan(status) {
        var data = {
            id: this.state.currentTicket.id,
            ticket_number: this.state.currentTicket.ticket_number,
            first_name: this.state.currentTicket.first_name,
            consumer_can_collect: !this.state.currentTicket.consumer_can_collect,
            consumer_can_collect2: this.state.currentTicket.consumer_can_collect2,
            consumer_collected: this.state.currentTicket.consumer_collected,
            promo: this.state.currentTicket.promo,
            email: this.state.currentTicket.email,
            last_name: this.state.currentTicket.last_name,
            marketing: this.state.currentTicket.marketing,
            store: this.state.currentTicket.store,
            repairer_picked_up: this.state.currentTicket.repairer_picked_up,
            privacy: this.state.currentTicket.privacy,
            phone: this.state.currentTicket.phone,
            repairer_can_collect: this.state.currentTicket.repairer_can_collect,
            date: this.state.currentTicket.date,
            notes: this.state.currentTicket.notes
        };

        ticketService.update(this.state.currentTicket.id, data)
            .then(response => {
                this.setState(prevState => ({
                    currentTicket: {
                        ...prevState.currentTicket,
                        consumer_can_collect: data.consumer_can_collect
                    }
                }));
                console.log(data.consumer_can_collect);


            })
            .catch(e => {
                console.log(e);
            });
    }
    onChangeConsumerCan2(status) {
        var data = {
            id: this.state.currentTicket.id,
            ticket_number: this.state.currentTicket.ticket_number,
            first_name: this.state.currentTicket.first_name,
            consumer_can_collect: this.state.currentTicket.consumer_can_collect,
            consumer_can_collect2: !this.state.currentTicket.consumer_can_collect2,
            consumer_collected: this.state.currentTicket.consumer_collected,
            promo: this.state.currentTicket.promo,
            email: this.state.currentTicket.email,
            last_name: this.state.currentTicket.last_name,
            marketing: this.state.currentTicket.marketing,
            store: this.state.currentTicket.store,
            repairer_picked_up: this.state.currentTicket.repairer_picked_up,
            privacy: this.state.currentTicket.privacy,
            phone: this.state.currentTicket.phone,
            repairer_can_collect: this.state.currentTicket.repairer_can_collect,
            date: this.state.currentTicket.date,
            notes: this.state.currentTicket.notes
        };

        ticketService.update(this.state.currentTicket.id, data)
            .then(response => {
                this.setState(prevState => ({
                    currentTicket: {
                        ...prevState.currentTicket,
                        consumer_can_collect2: data.consumer_can_collect2
                    }
                }));
                console.log(data.consumer_can_collect2);
                if (data.consumer_can_collect2) {
                    ticketService.sendEmailReady(data).then(response => {
                        console.log("Email sent");
                        ticketService.sendText(data).then(response => {
                            console.log("Text sent");
                        }).catch(e => {
                            console.log(e);
                        })
                    }).catch(e => {
                        console.log(e);
                    })
                }

            })
            .catch(e => {
                console.log(e);
            });
    }

    onChangeConsumerCollect(status) {
        console.log("Testing");
        var data = {
            id: this.state.currentTicket.id,
            ticket_number: this.state.currentTicket.ticket_number,
            first_name: this.state.currentTicket.first_name,
            consumer_can_collect: this.state.currentTicket.consumer_can_collect,
            consumer_can_collect2: this.state.currentTicket.consumer_can_collect2,
            consumer_collected: !this.state.currentTicket.consumer_collected,
            promo: this.state.currentTicket.promo,
            email: this.state.currentTicket.email,
            last_name: this.state.currentTicket.last_name,
            marketing: this.state.currentTicket.marketing,
            store: this.state.currentTicket.store,
            repairer_picked_up: this.state.currentTicket.repairer_picked_up,
            privacy: this.state.currentTicket.privacy,
            phone: this.state.currentTicket.phone,
            repairer_can_collect: this.state.currentTicket.repairer_can_collect,
            date: this.state.currentTicket.date,
            notes: this.state.currentTicket.notes
        };

        ticketService.update(this.state.currentTicket.id, data)
            .then(response => {
                this.setState(prevState => ({
                    currentTicket: {
                        ...prevState.currentTicket,
                        consumer_collected: data.consumer_collected
                    }
                }));
                console.log(response.data);
                if (data.consumer_collected) {
                    ticketService.sendEmailThankyou(data).then(response => {
                        console.log("Thank you Email sent");

                    }).catch(e => {
                        console.log(e);
                    })
                }
            })
            .catch(e => {
                console.log(e);
            });
    }



    onChangePromo(status) {
        console.log("Testing");
        var data = {
            id: this.state.currentTicket.id,
            ticket_number: this.state.currentTicket.ticket_number,
            first_name: this.state.currentTicket.first_name,
            consumer_can_collect: this.state.currentTicket.consumer_can_collect,
            consumer_can_collect2: this.state.currentTicket.consumer_can_collect2,
            consumer_collected: this.state.currentTicket.consumer_collected,
            promo: !this.state.currentTicket.promo,
            email: this.state.currentTicket.email,
            last_name: this.state.currentTicket.last_name,
            marketing: this.state.currentTicket.marketing,
            store: this.state.currentTicket.store,
            repairer_picked_up: this.state.currentTicket.repairer_picked_up,
            privacy: this.state.currentTicket.privacy,
            phone: this.state.currentTicket.phone,
            repairer_can_collect: this.state.currentTicket.repairer_can_collect,
            date: this.state.currentTicket.date,
            notes: this.state.currentTicket.notes
        };

        ticketService.update(this.state.currentTicket.id, data)
            .then(response => {
                this.setState(prevState => ({
                    currentTicket: {
                        ...prevState.currentTicket,
                        promo: data.promo
                    }
                }));
                console.log(response.data);
                if (data.promo) {
                    // ticketService.sendEmailThankyou(data).then(response => {
                    //     console.log("Promo");

                    // }).catch(e => {
                    //     console.log(e);
                    // })
                }
            })
            .catch(e => {
                console.log(e);
            });
    }





    getTicket(ticket_number) {
        console.log("Get " + ticket_number)
        TicketDataService.get(ticket_number)
            .then(response => {
                this.setState({
                    currentTicket: response.data
                });
                console.log("State = " + response.data.promo);
            })
            .catch(e => {
                console.log(e);
            });
    }

    updateTicket() {
        console.log("Updating " + this.state.currentTicket.id);
        TicketDataService.update(
            this.state.currentTicket.id,
            this.state.currentTicket
        )
            .then(response => {
                console.log(response.data);
                this.setState({
                    message: "The ticket was updated succesfully!"

                });
            })
            .catch(e => {
                console.log(e);
            });
    }
    deleteTicket() {
        TicketDataService.delete(this.state.currentTicket.id)
            .then(response => {
                console.log(response.data);
                this.props.router.navigate('/tickets');
            })
            .catch(e => {
                console.log(e);
            });
    }


    render() {
        const { currentTicket } = this.state;
        const { group } = this.state;
        

        return (
            <div>
                <div className="row">
                    <p />
                    <p />
                    <div className="d-flex col-md-6 offset-md-3 justify-content-center font-weight-bold" >
                        <h4>{currentTicket.first_name} {currentTicket.last_name} </h4>
                    </div>

                    <p />
                </div>
                <div className="row">

                    <div className="d-flex col-md-6 offset-md-3 justify-content-center user-title">
                        For repair company
                    </div>

                    <div className="d-flex col-md-6 offset-md-3 justify-content-center grey-box">
                        <div className={group === "tnf" || group === "brand" ? "grey-check-disabled" : "grey-check"}>
                            <label className="form-check-label grey-check-2 " htmlFor="flexCheckChecked">
                                {currentTicket.repairer_picked_up ?
                                    <input className="form-check-input p-2" onClick={() => this.onChangeRepairerPickedUp(false)} defaultChecked type="checkbox" value="" id="flexCheckChecked">
                                    </input>
                                    :
                                    <input className="form-check-input p-2" onClick={() => this.onChangeRepairerPickedUp(false)} type="checkbox" value="" id="flexCheckChecked">
                                    </input>
                                }
                                <span className="grey-check-2">
                                    <span className="checkbox-indicator">
                                        <span className="tick-mark-2">&#10003;</span>
                                    </span>
                                    Shoe picked up by repairer
                                </span>


                            </label>
                        </div>
                    </div>
                    <div className="d-flex col-md-6 offset-md-3 justify-content-center grey-box">
                        <div className={group === "tnf" || group === "brand" ? "grey-check-disabled" : "grey-check"} style={{ marginTop: "-2px" }}>
                            <label className="form-check-label grey-check-2 " htmlFor="flexCheckChecked2">

                                {currentTicket.consumer_can_collect ?
                                    <input className="form-check-input p-2" onClick={() => this.onChangeConsumerCan(false)} defaultChecked type="checkbox" value="" id="flexCheckChecked2">
                                    </input> :
                                    <input className="form-check-input p-2" onClick={() => this.onChangeConsumerCan(false)} type="checkbox" value="" id="flexCheckChecked2">
                                    </input>
                                }
                                <span className="grey-check-2">
                                    <span className="checkbox-indicator">
                                        <span className="tick-mark-2">&#10003;</span>
                                    </span>
                                    Consumer can collect
                                </span>


                            </label>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="d-flex col-md-6 offset-md-3 justify-content-center">
                        <br></br>
                    </div>
                </div>
                <div className="row">

                    <div className="d-flex col-md-6 offset-md-3 justify-content-center user-title">
                        For in-store only
                    </div>
                    <div className="d-flex col-md-6 offset-md-3 justify-content-center grey-box">

                        <div className={group === "repairer" || group === "brand" ? "grey-check-disabled" : "grey-check"}>
                            <label className="form-check-label grey-check-2 " htmlFor="flexCheckChecked5">
                                {currentTicket.promo ?

                                    <input className="form-check-input p-2" onClick={() => this.onChangePromo(false)} defaultChecked type="checkbox" value="" id="flexCheckChecked5">
                                    </input> :
                                    <input className="form-check-input p-2" onClick={() => this.onChangePromo(false)} type="checkbox" value="" id="flexCheckChecked5">
                                    </input>
                                }
                                <span className="grey-check-2">
                                    <span className="checkbox-indicator">
                                        <span className="tick-mark-2">&#10003;</span>
                                    </span>
                                    Free Promotion
                                </span>


                            </label>
                        </div>
                    </div>

                    <div className="d-flex col-md-6 offset-md-3 justify-content-center grey-box">

                        <div className={group === "repairer" || group === "brand" ? "grey-check-disabled" : "grey-check"}>
                            <label className="form-check-label grey-check-2 " htmlFor="flexCheckChecked3">
                                {currentTicket.consumer_collected ?

                                    <input className="form-check-input p-2" onClick={() => this.onChangeConsumerCollect(false)} defaultChecked type="checkbox" value="" id="flexCheckChecked3">
                                    </input> :
                                    <input className="form-check-input p-2" onClick={() => this.onChangeConsumerCollect(false)} type="checkbox" value="" id="flexCheckChecked3">
                                    </input>
                                }
                                <span className="grey-check-2">
                                    <span className="checkbox-indicator">
                                        <span className="tick-mark-2">&#10003;</span>
                                    </span>
                                    Consumer collected the items
                                </span>


                            </label>
                        </div>
                    </div>


                    <div className="d-flex col-md-6 offset-md-3 justify-content-center grey-box">
                        <div className={group === "repairer" || group === "brand" ? "grey-check-disabled" : "grey-check"} style={{ marginTop: "-2px" }}>
                            <label className="form-check-label grey-check-2 " htmlFor="flexCheckChecked4">

                                {currentTicket.consumer_can_collect2 ?
                                    <input className="form-check-input p-2" onClick={() => this.onChangeConsumerCan2(false)} defaultChecked type="checkbox" value="" id="flexCheckChecked4">
                                    </input> :
                                    <input className="form-check-input p-2" onClick={() => this.onChangeConsumerCan2(false)} type="checkbox" value="" id="flexCheckChecked4">
                                    </input>
                                }
                                <span className="grey-check-2">
                                    <span className="checkbox-indicator">
                                        <span className="tick-mark-2">&#10003;</span>
                                    </span>
                                    Consumer can collect (sends email to consumer)
                                </span>


                            </label>
                        </div>
                    </div>
                </div>



                <div className="row">
                    <div className="d-flex col-md-2 offset-md-3 justify-content-center check-text">
                        <div >
                            <img className="qrcode" src={"https://d33turd3a1vf87.cloudfront.net/" + currentTicket.ticket_number + ".png"} />
                        </div>
                    </div>

                    <div className="d-flex col-md-4 justify-content-center check-text">
                        <ul className="flat-list">
                            <li>
                                <div className="ticket-property-1">
                                    <div className="ticket-property-title">
                                        Ticket Number
                                    </div>
                                    <div className="ticket-property-value">
                                        {currentTicket.ticket_number}
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="ticket-property-1">
                                    <div className="ticket-property-title">
                                        Store
                                    </div>
                                    <div className="ticket-property-value">
                                        {currentTicket.store}
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="ticket-property-1">
                                    <div className="ticket-property-title">
                                        Contact email
                                    </div>
                                    <div className="ticket-property-value">
                                        {currentTicket.email}
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="ticket-property-1">
                                    <div className="ticket-property-title">
                                        Contact phone number
                                    </div>
                                    <div className="ticket-property-value">
                                        {currentTicket.phone}
                                    </div>
                                </div>
                            </li>


                        </ul>
                    </div>





                    <div className="row">
                        <div className="d-flex col-md-2 offset-md-3 justify-content-center check-text">
                            <button type="button" className="image-btn" data-bs-toggle="modal" data-bs-target="#image1modal">

                                <img className="shoe-image" src={currentTicket.shoe_image} />
                            </button>



                        </div>



                        <div className="d-flex col-md-2 justify-content-center check-text">
                            <button type="button" className="image-btn" data-bs-toggle="modal" data-bs-target="#image2modal">

                                <img className="shoe-image" src={currentTicket.shoe_image2} />
                            </button>
                        </div>

                        <div className="d-flex col-md-2 justify-content-center check-text">
                            <button type="button" className="image-btn" data-bs-toggle="modal" data-bs-target="#image3modal">

                                <img className="shoe-image" src={currentTicket.shoe_image3} />
                            </button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="d-flex col-md-6 offset-md-3 justify-content-start title-text">
                            Details
                        </div>


                    </div>




                </div>

                <ItemsList />
                <div className="row">
                    <div className="d-flex col-md-6 offset-md-3 justify-content-start title-text">
                        Notes
                    </div>


                </div>
                <div className="row">
                    <div className="d-flex col-md-6 offset-md-3 justify-content-center">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Notes..."
                            value={currentTicket.notes}
                            onChange={this.onChangeNotes}

                        />


                    </div>


                </div>
                <div className="row">
                    
                    

                </div>
                <div className="row">
                    <div className="d-flex col-md-6 offset-md-3 justify-content-center">
                        <button type="button" className="delete-btn btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">Delete Ticket</button>






                    </div>


                </div>
                <div className="row">
                    <div className="d-flex col-md-6 offset-md-3 justify-content-center">
                        <button type="button" className="delete-btn btn btn-primary" onClick={this.saveNotes}>Save Ticket</button>






                    </div>


                </div>


                <div className="row">
                    <div className="d-flex col-md-6 offset-md-3 justify-content-center">
                        <button type="button" className="delete-btn btn btn-primary" ><Link to={`/`} style={{ textDecoration: 'none' }}>Back</Link></button>






                    </div>


                </div>
                <div className="modal" id="exampleModal" tabIndex="-1" data-bs-backdrop="static"
                    data-bs-keyboard="false" aria-labelledby="deleteModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="deleteModalLabel">Delete Ticket?</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">

                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure you want to delete this ticket?
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" onClick={this.deleteTicket} className="btn btn-primary" data-bs-dismiss="modal">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>


            </div >



        );

    }
}

export default withRouter(Ticket);