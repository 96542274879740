import React, { Component } from "react";
import TicketDataService from "../services/ticket.service";
import { withRouter } from '../common/with-router';
import ticketService from "../services/ticket.service";
import { Modal } from 'bootstrap'
import { Auth } from 'aws-amplify';
import PhotoList from "./photo-list.component";


class ItemsList extends Component {

    constructor(props) {
        super(props);

        this.retrieveItems = this.retrieveItems.bind(this);

        this.state = {
            items: [],
            id: null,
            userid:null,
            itemid:null,
            searchTicketID: ""
        };

        //this.searchAll = this.searchAll.bind(this);
    }

    async componentDidMount() {
        const user = await Auth.currentAuthenticatedUser();

        const groupname = user.signInUserSession.accessToken.payload["cognito:groups"];
        this.setState({ group: groupname });

        console.log("ID = "+this.props.router.params.id);
        console.log("ITEM ID = "+this.props.router.params.itemid);
        console.log("USER ID = "+this.props.router.params.userid);
        this.retrieveItems(this.props.router.params.id);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps == undefined) {
            return false;
        }



    }

    retrieveItems(userid) {
        this.setState({
            searchTicketID: userid
        }, () => {
            TicketDataService.getItems(userid)
                .then(response => {
                    this.setState({
                        items: response.data
                    });
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(2);

                })
        })
    }




    render() {
        const { items, searchTicketID } = this.state;

        return (
            <div>

<div className="row ">
<div className="d-flex col-md-6 offset-md-3 justify-content-start user-title">
<ul className="list-group">
                        {items && items.map((item, index) => (
                            <div>
                                <li
                              

                                key={index}
                            >
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <span id="item1">Item {index+1}</span>
                                        <p />
                                        <span id="item2">{item.service}</span>
                                        <PhotoList itemid={item.id} />
                                        {item.notes}
                                    </div>
                                </div>
                            </li>
                            
                            <p />
                            </div>
                        ))}
                    </ul>
       </div>
</div>
</div>

        );

    }
}

export default withRouter(ItemsList);