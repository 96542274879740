import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route, Link } from "react-router-dom";
import "./App.css";
import { useNavigate } from "react-router-dom";
import AddTicket from "./components/add-ticket.component";
import Ticket from "./components/ticket.component";
import TicketsList from "./components/ticket-list.component";
import ItemList from "./components/item-list.component";
import PhotoList from "./components/photo-list.component";
import TicketsListQR from "./components/ticket-list-qr.component";
import ViewStats from "./components/statistics.component";
import "@aws-amplify/ui-react/styles.css";
import {
  withAuthenticator,
  Button,
  Heading,
  Image,
  View,
  Card,
} from "@aws-amplify/ui-react";

import { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';


// >>New - Configuring Auth Module
Auth.configure(awsconfig);
export const Item = () => {
  let navigate = useNavigate();
  return (
    <>
      <button className="signout-btn" onClick={() => Auth.signOut()}>Logout</button>
      <button className="back-btn" onClick={() => navigate(-1)}>Back</button>
    </>
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group: ''
    }
  };
  async componentDidMount() {
    const user = await Auth.currentAuthenticatedUser();

    const groupname = user.signInUserSession.accessToken.payload["cognito:groups"];
    this.setState({ group: groupname });
  }

  render() {
    const { group } = this.state;
    console.log(group);

    return (
      
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <a className="navbar-brand" href="#">Data</a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">

              {(group[0] === "admin" || group[0] === "tnf" || group[0] === "repairer" || group[0] === "brand") &&
                <li className="nav-item ">
                  <Link to={"/tickets/"} className="nav-link">
                    Regent Street
                  </Link>
                </li>


              }


              {(group[0] === "admin" || group[0] === "repairer" || group[0] === "brand") &&
                <li className="nav-item">

                  <Link to={"/stats"} className="nav-link">
                    View Stats
                  </Link>
                </li>
              }

            </ul>
            <ul className="navbar-nav ms-auto">
              <li className="nav-item ">

              </li>
            </ul>

            <ul className="navbar-nav ms-auto">
              <li className="nav-item ">

                <Item />

              </li>
            </ul>
          </div>
        </nav>


        <div className="container mt-3">
          <Routes>
            <Route path="/" element={<TicketsListQR />} />
            <Route path="/tickets/:store" element={<TicketsListQR />} />
            <Route path="/tickets/" element={<TicketsListQR />} />
            <Route path="/stats" element={<ViewStats />} />
            <Route path="/ticket/:id" element={<Ticket />} />
            <Route path="/item/:userid" element={<ItemList />} />
            <Route path="/photo/:itemid" element={<PhotoList />} />
          </Routes>
        </div>
      </div>
    );


  }
}

export default withAuthenticator(App, { hideSignUp: true, includeGreetings: true });
