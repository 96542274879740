import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import {BrowserRouter} from "react-router-dom";
import App from './App';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import "@fontsource/inter"; // Defaults to weight 400
Amplify.configure(config);



const container = document.getElementById("root");
const root = createRoot(container);


root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);


